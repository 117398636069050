import React from "react";

import {
    Create,
    FormTab,
    SaveButton,
    TabbedForm,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    RadioButtonGroupInput,
    TextInput,
    NumberInput,
    BooleanInput,
    Toolbar,
} from 'react-admin';


const NotificationEditToolbar = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton
            label="Send Notification"
            redirect="/notifications"
            submitOnEnter={true}
        />
    </Toolbar>
);

const NotificationCreate = ({permissions, ...props}) => (
    <Create {...props}>
        <TabbedForm toolbar={<NotificationEditToolbar permissions={permissions}/>}>
            <FormTab label="Notification" path="">
                <SelectInput source="notificationType" choices={[
                    {id: 'push', name: 'Push'},
                    {id: 'inapp', name: 'In App'},
                    {id: 'all', name: 'Both Type'},
                ]}/>
                <ArrayInput source="title" size="32x32">
                    <SimpleFormIterator>
                        <TextInput source="tr"/>
                        <TextInput source="en"/>
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="body" size="32x32">
                    <SimpleFormIterator>
                        <TextInput source="tr"/>
                        <TextInput source="en"/>
                    </SimpleFormIterator>
                </ArrayInput>
                <NumberInput source="expireDay" label="Expire Day" size="12x12" />
                <ArrayInput source="queryArray" size="32x32">
                    <SimpleFormIterator>
                        <SelectInput source="query"
                        choices={[
                            {id: 'isPremium', name: 'Premium'},
                            {id: 'isAbaza', name: 'Abaza'},
                            {id: 'isWoman', name: 'Woman'},
                            {id: 'isAdmin', name: 'Admin'},
                        ]}/>
                        <BooleanInput label="false-strue" source="queryChoise" />
                    </SimpleFormIterator>
                </ArrayInput>
                <RadioButtonGroupInput label="Platform" source="platform" choices={[
                    { id: 'ios', name: 'İos' },
                    { id: 'android', name: 'Android'},
                ]} />
                <RadioButtonGroupInput source="scoreKind" choices={[
                    { id: 'gte', name: 'Büyüktür ve Eşittir' },
                    { id: 'lte', name: 'Küçüktür ve Eşittir' },
                ]} />
                <NumberInput source="score" label="Score" size="12x12" />
                <NumberInput source="day" label="Day" size="12x12" />
                <TextInput source="type" label="Icon url"/>
                <ArrayInput source="actions" size="32x32">
                    <SimpleFormIterator>
                        <TextInput source="data"/>
                        <TextInput source="action"/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
);
export default NotificationCreate;
