import React from 'react';
import PropTypes from 'prop-types';
import {
    Show,
    Tab, TabbedShowLayout, TextField, BooleanField, NumberField, ImageField, DateField
} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

const styles = {
    image: {
        '& img': {
            maxHeight: '5rem',
            backgroundColor: '#4b4b4b'
        }
    },
};

const MyImageField = withStyles(styles)(({classes, ...props}) => (
    <ImageField className={classes.image} {...props} />
));

const ChannelShow = ({permissions, ...props}) => (
    <Show label="View" {...props}>
        <TabbedShowLayout>
            <Tab label="View">
                <TextField source="_id"/>
                <TextField label="Channel" source="translations[0].text"/>
                <BooleanField source="isActive"/>
                <MyImageField label="icon" source="icon" title="Picture"/>
                <NumberField source="onlineCount"/>
                <DateField source="updatedAt" locales="en-GB" showTime/>
                <DateField source="createdAt" locales="en-GB" showTime/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

ChannelShow.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    permissions: PropTypes.string,
};

export default ChannelShow;