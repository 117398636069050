import React from 'react';
import TabbedDatagrid from './TabbedDatagrid'
import {Filter, List, SearchInput, SelectInput,} from 'react-admin';

const ChannelFilter = ({permissions, ...props}) => (
    <Filter {...props} >
        <SearchInput source="q" alwaysOn/>
        <SelectInput label="translations" source="translations"
                     choices={[
                         {id: 'tr', name: 'tr'},
                         {id: 'en', name: 'en'},
                         {id: 'de', name: 'de'}
                     ]}/>
        <SelectInput label="App language" source="language" alwaysOn
                     choices={[
                         {id: 'tr', name: 'Turkish'},
                         {id: 'en', name: 'English'},
                         {id: 'es', name: 'Spanish'},
                         {id: 'hi', name: 'Hindu'},
                         {id: 'ar', name: 'Arabia'}
                     ]}/>

    </Filter>
);

const ChannelList = ({permissions, ...props}) => (
    <List {...props}
          filters={<ChannelFilter permissions={permissions}/>}
          filterDefaultValues={{allowedCountry: 'tr'}}>
        <TabbedDatagrid/>
    </List>
);

export default ChannelList;