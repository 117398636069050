const rowStyle = (record, index, defaultStyle = {}) => {
    if (record.color)
        return {
            ...defaultStyle,
            borderLeftColor: '#'+record.color,
            borderLeftWidth: 20,
            borderLeftStyle: 'solid',
        };

    return defaultStyle;
};

export default rowStyle;
