import ChannelCreate from './ChannelCreate';
import ChannelEdit from './ChannelEdit';
import ChannelList from './ChannelList';
import ChannelShow from './ChannelShow';

export default {
    list: ChannelList,
    show: ChannelShow,
    create: ChannelCreate,
    edit: ChannelEdit,
};