import React from "react";
import {
    ArrayInput,
    BooleanInput,
    Create,
    FormTab,
    SaveButton,
    SimpleFormIterator,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar
} from 'react-admin';
// import { DateTimeInput } from 'react-admin-date-inputs';
import { ColorInput } from 'react-admin-color-input';

const ChannelEditToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            redirect="/channels"
            submitOnEnter={true} />

    </Toolbar>
);
const ChannelCreate = ({ permissions, ...props }) => (
    <Create  {...props}>
        <TabbedForm toolbar={<ChannelEditToolbar permissions={permissions} />}>
            <FormTab label="Channel Create" path="">
                <TextInput source="icon" resettable
                    defaultValue="https://s3.eu-central-1.amazonaws.com/blind-assets/icons/LetsMeet.png" />
                <TextInput source="iconName" defaultValue="tr" />
                <ColorInput source="color" picker="Swatches" />
                <ArrayInput source="translations" size="32x32">
                    <SimpleFormIterator>
                        <TextInput source="language" defaultValue="en" />
                        <TextInput source="text" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="allowedCountryDeviceLanguagePairs" label='ALLOWED COUNTRY'>
                    <SimpleFormIterator>
                        <TextInput label="allowedCountry" defaultValue="*-*" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="capabilities" label='Chat Or Voice Feature'>
                    <SimpleFormIterator>
                        <TextInput label="allowedFeature" defaultValue="voice" resettable/>
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput source="isActive" label="Channel Open/Close " defaultValue='true' />
                <SelectInput source="type" allowEmpty emptyValue="" choices={[
                    { id: 'only-woman', name: 'Woman Channel' },
                ]} />
                <ArrayInput source="dates">
                    <SimpleFormIterator>
                        {/* <DateTimeInput source="startDate" label="startDate"
                            options={{
                                keyboard: true,
                                format: 'dd/MM/yyyy, HH:mm',
                                ampm: false,
                                clearable: true
                            }} />
                        <DateTimeInput source="endDate" label="endDate"
                            options={{
                                keyboard: true,
                                format: 'dd/MM/yyyy, HH:mm',
                                ampm: false,
                                clearable: true
                            }} /> */}
                        <BooleanInput source="repeat" />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
);
export default ChannelCreate;
