import React from 'react';
import {fetchUtils,Admin, Resource } from 'react-admin';
import users from './users';
import notifications from './notifications';
import channels from './channels';
import authProvider from './authProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import {blue} from  '@material-ui/core/colors/';
import Dashboard from './dashboard';
import CustomDataProvider from './dataProvider';
import customRoutes from './customRoutes';


const theme = createMuiTheme({
    palette: {
        type: 'light', // Switching the dark mode on is a single erty value change.
        secondary: blue,
        primary: blue,
    },
    typography: {
        useNextVariants: true,
    },
});
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
};
const dataProvider = CustomDataProvider('https://panel-dot-migrate-blindid.nw.r.appspot.com/api/',httpClient);
const App = () => (
    <Admin
           customRoutes={customRoutes}
           theme={theme}
           dashboard={Dashboard}
           authProvider={authProvider}
           dataProvider={dataProvider} >

        {permissions => [
            <Resource name="admin" options={{ label: 'admin' }}/>,
        permissions === 'admin' ? <Resource name="users" {...users} options={{ label: 'Users' }}/>
        : null,
        permissions === 'admin' ? <Resource name="channels" {...channels} options={{ label: 'Channels' }}/>
        : null,
        permissions === 'admin' ? <Resource name="notifications" {...notifications} options={{ label: 'Notifications' }}/> : null,
        permissions === 'moderator' ? <Resource name="channels" {...channels} options={{ label: 'Channels' }}/>
        : null,
        ]}
    </Admin>
);
export default App;
