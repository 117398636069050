import React from 'react';
import PropTypes from 'prop-types';
import {
    Show,
    Tab, TabbedShowLayout, TextField, BooleanField, NumberField, ImageField, DateField, ArrayField, Datagrid
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import withStyles from '@material-ui/core/styles/withStyles';
import MakeWomen from '../components/makewomen';

export const styles = {
    first: {display: 'inline-block'},
    others: {display: 'inline-block', marginLeft: 32},
    divider: {display: 'none', marginLeft: 32},
};
const UserShow = ({classes, permissions, ...props}) => (
    <Show label="View" {...props}>
        <TabbedShowLayout>
            <Tab label="View">
                <ImageField source="avatar.url"/>
                <MakeWomen props={props} />
                <TextField source="nickname"
                           className={classes.first}/>
                <TextField source="username"
                           className={classes.others}/>
                <NumberField source="score"
                             className={classes.others}/>
                <NumberField source="userGold.amount"
                             label="Gold Amount"
                             className={classes.others} />
                <TextField source="biography"/>
                <TextField source="platform"
                           className={classes.first}/>
                <TextField source="appVersion"
                           className={classes.others}/>
                <TextField label="Last Channel" source="channel.translations[1].text"
                           className={classes.others}/>
                <TextField source="language"
                           className={classes.others}/>
                <TextField source="gcloudCountry"
                           className={classes.others}/>
                <TextField source="deviceLanguage"
                           className={classes.others}/>
                <TextField source="divider" label=""/>
                <BooleanField source="isOnline"
                              className={classes.first}/>
                <BooleanField source="isPremium"
                              className={classes.others}/>
                <BooleanField source="isWoman"
                              className={classes.others}/>
                <BooleanField source="isAbaza"
                              className={classes.others}/>
                <BooleanField source="isBanned"
                              className={classes.others}/>
                <BooleanField source="betaTester"
                              className={classes.others}/>
                <TextField source="womanAwardTs"
                              className={classes.others}/>
                <TextField source="divider" label=""/>
                <DateField source="updatedAt" locales="en-GB" showTime
                           className={classes.first}/>
                <DateField source="createdAt" locales="en-GB" showTime
                           className={classes.others}/>
                <TextField source="divider" label=""/>
                <NumberField source="limits.availableFriendRequest" label={"Friend Request"}
                             className={classes.first}/>
                <NumberField source="limits.availableDoubleCall" label="Double Time"
                             className={classes.others}/>
                <TextField label="Total Friends" source="friends.length"
                           className={classes.others}/>
                <TextField source="divider" label=""/>
                <TextField source="orderId" label="OrderId"
                           className={classes.first}/>
                <TextField source="paymentObj.productId" label="Product Id"
                           className={classes.others} />
                <TextField source="divider" label=""/>
                <TextField source="paymentObj.purchaseDateMs" label="Purchase Date"
                           className={classes.first}/>
                <TextField source="paymentObj.expiresDateMs" label="Expires Date"
                           className={classes.others}/>
            </Tab>
            <Tab label="Friend">
                <TextField label="Total Friends" source="friends.length"/>
                <ArrayField source="friends">
                    <Datagrid>
                        <TextField source="id"/>
                        <TextField source="nickname"/>
                        <TextField source="voiceId"/>
                        <DateField source="updatedAt" locales="en-GB" showTime/>
                        <DateField source="createdAt" locales="en-GB" showTime/>
                    </Datagrid>
                </ArrayField>
            </Tab>

            <Tab label="oldPaymentList">
                <ArrayField source="oldPaymentList">
                    <Datagrid>
                        <TextField source="orderId"/>
                        <TextField source="purchaseDateMs"/>
                        <TextField source="expiresDateMs"/>
                        <BooleanField source="autoRenewing"/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Gold">
                <NumberField source="userGold.amount"/>
                <ArrayField source="userGold.transactions">
                    <Datagrid sort={{ field: 'transactions.updatedAt', order: 'DESC' }}>
                        <TextField source="type"/>
                        <TextField source="detail"/>
                        <TextField source="change"/>
                        <DateField source="updatedAt" locales="en-GB" showTime/>
                        <DateField source="createdAt" locales="en-GB" showTime/>
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="Removed Friends">
                <ArrayField source="removedFriends">
                    <Datagrid>
                        <TextField source="id"/>
                        <TextField source="nickname"/>
                        <TextField source="voiceId"/>
                        <DateField source="updatedAt" locales="en-GB" showTime/>
                        <DateField source="createdAt" locales="en-GB" showTime/>
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

UserShow.propTypes = {
    location: PropTypes.object,
    match: PropTypes.object,
    permissions: PropTypes.string,
};

export default withStyles(styles)(UserShow);
