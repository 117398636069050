import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, crudUpdateMany} from 'react-admin';

class ResetScoreButton extends Component {

    handleClick = () => {
        const {basePath, crudUpdateMany, resource, filterValues} = this.props;
        crudUpdateMany(resource, filterValues, {score: 30}, basePath);
    };

    render() {
        return (
            <Button color="primary" label="Reset Score" onClick={this.handleClick}/>
        );
    }
}

export default connect(undefined, {crudUpdateMany})(ResetScoreButton);