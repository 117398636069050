import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginLeft: '1em',
        marginTop: 5,
    },
    card: {
        padding: 16,
        minHeight: 52,
    },
};

const Instant = ({type, value, translate, classes}) => (
    <div className={classes.main}>
        <Card className={classes.card}>
            <Typography  variant="h6" component="h1" color="textSecondary">
                {type}
            </Typography>
            <Typography variant="h4" component="h1">
                {value}
            </Typography>
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
);

export default enhance(Instant);