class service {
    static async dataFetcher(command) {
        const response = await fetch(`https://panel-dot-migrate-blindid.nw.r.appspot.com/api/users/dashboard/${command}`, {
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            })
        });
        return response.json();
    }
}

export default service;