import React from "react";
import { addPremium } from './fetch';

const styles = {
    flex: { display: 'flex' },
    flexSmall: { display: 'block' },
    flexSmallTop: { display: 'block', marginTop: '1em', marginBottom: '1em' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    flexRow: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justify: 'flex-start' },
    leftCol: { flex: 5, marginRight: '1em' },
    leftColSmall: { flex: 5 },
    rightCol: { flex: 2, marginLeft: '1em' },
    middleCol: { flex: 8, marginRight: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    singleButton: { width: '10em', marginTop: '1em' }
};

class AddPremium extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: 0, error: false };
        this.handleChange = this.handleChange.bind(this);

    }

    onClick(user) {
        if (this.state.value.length > 0) {
            const token = localStorage.getItem('token');
            const {
                username,
                nickname,
                score,
                limits,
                _id,
                id
            } = user;
            addPremium(token, {
                username,
                nickname,
                score,
                limits,
                _id,
                id
            }, { addPremium: true, premiumDay: parseInt(this.state.value) }).then(done => {
                console.log(done);
            }).catch(err => {
                console.log(err);
            })
        } else {
            alert("Premium olarak eklenecek zamanı kontrol edin!")
        }
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    render() {
        return (
            <div style={styles.flexColumn}>
                <label>
                    Premium Day:
                    <input type="text" value={this.state.value} onChange={this.handleChange} />
                </label>
                <button style={styles.singleButton} onClick={() => {
                    this.onClick(this.props.record)
                }} type="button">Add Premium</button>
            </div>
        )
    }
}

export default AddPremium;
