
const apiUrl = "https://panel-dot-migrate-blindid.nw.r.appspot.com/api/users/makeWoman";

export function makeWoman(token, userId) {
    return new Promise(
        function (resolve, reject) {
            fetch(`${apiUrl}`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': 'no-cors',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    userId: userId
                })
            })
                .then(response => response.json())
                .then(data => {
                    resolve(data)
                }).catch(err => {
                    console.log(err)
                    reject(err)
                });
        })
}

export function addPremium(token, user, addPremiumDay) {
    var userApiPatch = `https://panel-dot-migrate-blindid.nw.r.appspot.com/api//users/admin/${user.id}`
    var body = { ...user, ...addPremiumDay }
    return new Promise(
        function (resolve, reject) {
            fetch(`${userApiPatch}`, {
                method: 'patch',
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': 'no-cors',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(body)
            })
                .then(response => response.json())
                .then(data => {
                    resolve(data)
                }).catch(err => {
                    console.log(err)
                    reject(err)
                });
        })
}


