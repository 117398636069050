import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {translate} from 'react-admin';

const styles = theme => ({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 10,
    },
    card: {
        padding: 15,
        textAlign: 'left',
        marginLeft: 15
    },
    title: {
        padding: '0 12px',
    },
    value: {
        padding: '0 15px',
        minHeight: 32,
    },

    listItemText: {
        paddingRight: 0,
    },
});

const PaymentStats = ({visitors = [], classes}) => (
    <div className={classes.main}>
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
            </Typography>
            <Typography
                variant="h5"
                component="h2"
                className={classes.value}>
                Payment Statics
            </Typography>
            <Divider/>
            <List>
                {visitors.map(record => (
                    <ListItem
                        key={record.id.toString()}>
                        <ListItemText
                            primary={`7 Day Android: ${record.sevenDayAnd}`}
                            className={classes.listItemText}/>
                        <ListItemText
                            primary={`7 Day Ios: ${record.sevenDayIos}`}
                            className={classes.listItemText}/>
                        <ListItemText
                            primary={`24 Hours Android: ${record.twentyfourAnd}`}
                            className={classes.listItemText}/>
                        <ListItemText
                            primary={`24 Hours Ios: ${record.twentyfourIos}`}
                            className={classes.listItemText}/>
                        <ListItemText
                            primary={`30 Days Android: ${record.thirtyDayAnd}`}
                            className={classes.listItemText}/>
                        <ListItemText
                            primary={`30 Days Ios: ${record.thirtyDayIos}`}
                            className={classes.listItemText}/>
                    </ListItem>
                ))}
            </List>
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(PaymentStats);