import React from "react";
import PropTypes from 'prop-types';
import {
    DisabledInput,
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    NumberInput,
    BooleanField
} from 'react-admin';

import AddPremium from '../components/addPremium';

const UserTitle = ({ record }) => {
    return <span>User {record ? `"${record.nickname}"` : ''}</span>;
};
const UserEdit = ({ permissions, ...props }) => (
    <Edit title={<UserTitle />}  {...props}>
        <TabbedForm>
            <FormTab label="Edit" path="">
                <DisabledInput source="id" />
                <TextInput source="username" />
                <TextInput source="nickname" />
                <NumberInput source="score" />
                <NumberInput source="limits.availableFriendRequest" />
                <NumberInput source="limits.availableDoubleCall" />
                <BooleanInput source="addPremium" />
                <NumberInput source="premiumDay" />
                <BooleanInput label="Ban" source="addBan" />
                <NumberInput source="banDays" />
                <BooleanInput source="betaTester" />
            </FormTab>
            <FormTab label="Gold">
                <NumberInput label="Gold ekle" source="increaseGold" />
                <NumberInput label="Gold azalt" source="reduceGold" />
            </FormTab>

            <FormTab label="Premium Ekle">
                <BooleanField source="isPremium"
                />
                <AddPremium props={props} />
            </FormTab>

        </TabbedForm>
    </Edit>
);
UserEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};
export default UserEdit;
