import React from "react";
import PropTypes from 'prop-types';

import {
    DisabledInput,
    Edit,
    FormTab,
    TabbedForm,
    SelectInput,
    TextInput,
    BooleanInput,
    SimpleFormIterator,
    ArrayInput,
} from 'react-admin';
// import {DateTimeInput} from 'react-admin-date-inputs';
import {ColorInput} from 'react-admin-color-input';

const ChannelTitle = ({record}) => {
    return <span>Channel {record ? `"${record.translations[0].text}"` : ''}</span>;
};

const ChannelEdit = ({permissions, ...props}) => (
    <Edit title={<ChannelTitle/>} label="Edit"  undoable={false} {...props}>
        <TabbedForm>
            <FormTab label="Edit" path="">
                <DisabledInput source="id"/>
                <TextInput source="icon"/>
                <TextInput source="iconName"/>
                <ColorInput source="color" picker="Swatches"/>
                <ArrayInput source="translations">
                    <SimpleFormIterator>
                        <TextInput source="language"/>
                        <TextInput source="text"/>
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="allowedCountryDeviceLanguagePairs" label='ALLOWED COUNTRY'>
                    <SimpleFormIterator>
                        <TextInput label="allowedCountry" defaultValue="*-*" resettable/>
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="capabilities" label='Chat Or Voice Feature'>
                    <SimpleFormIterator>
                        <TextInput label="allowedFeature" defaultValue="voice" resettable/>
                    </SimpleFormIterator>
                </ArrayInput>
                <SelectInput source="type" allowEmpty emptyValue="" choices={[
                    { id: 'only-woman', name: 'Woman Channel' },
                ]} />
                <ArrayInput source="dates">
                    <SimpleFormIterator>
                        {/* <DateTimeInput source="startDate" label="startDate"
                                       options={{
                                           keyboard: true,
                                           format: 'dd/MM/yyyy, HH:mm',
                                           ampm: false,
                                           clearable: true
                                       }}/>
                        <DateTimeInput source="endDate" label="endDate"
                                       options={{
                                           keyboard: true,
                                           format: 'dd/MM/yyyy, HH:mm',
                                           ampm: false,
                                           clearable: true
                                       }}/> */}
                        <BooleanInput source="repeat"/>
                    </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput source="isActive"/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

ChannelEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};
export default ChannelEdit;
