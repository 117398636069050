import React, {Fragment} from 'react';
import ChannelEditExpand from './ChannelEditExpand';
import {withStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    DateField,
    EditButton,
    ImageField,
    NumberField,
    Responsive,
    SingleFieldList,
    TextField,
} from 'react-admin';
import rowStyle from './rowStyle';
const styles = {
    image: {
        '& img': {
            maxHeight: '2rem',
            backgroundColor: '#4b4b4b'
        }
    },
};
const MyImageField = withStyles(styles)(({classes, ...props}) => (
    <ImageField className={classes.image} {...props} />
));
class TabbedDatagrid extends React.Component {
    tabs = [
        {id: 'tr', name: 'tr'},
        {id: 'us', name: 'us'},
        {id: 'in', name: 'in'},
        {id: 'all', name: 'all'},
    ];
    state = {us: [], tr: [], in: [], all: []};
    static getDerivedStateFromProps(props, state) {
        if (props.ids !== state[props.filterValues.allowedCountry]) {
            return {...state, [props.filterValues.allowedCountry]: props.ids};
        }
        return null;
    }
    handleChange = (event, value) => {
        const {filterValues, setFilters} = this.props;
        setFilters({...filterValues, allowedCountry: value});
    };
    render() {
        const {filterValues, ...props} = this.props;
        return (
            <Fragment>
                <Tabs
                    value={filterValues.allowedCountry}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab
                            key={choice.id}
                            label={choice.name}
                            value={choice.id}
                        />
                    ))}
                </Tabs>
                <Divider/>
                <Responsive
                    small={
                        <div>
                            {filterValues.allowedCountry === 'tr' && (
                                <Datagrid {...props} ids={this.state.tr}
                                          rowStyle={rowStyle}
                                          expand={<ChannelEditExpand/>}
                                          rowClick="edit">
                                    <TextField source="translations[0].text" label="Channel Name"/>
                                    <NumberField source="onlineCount"/>
                                    <BooleanField source="isActive"/>
                                </Datagrid>
                            )}
                            {filterValues.allowedCountry === 'us' && (
                                <Datagrid {...props} ids={this.state.us}
                                          rowStyle={rowStyle}
                                          expand={<ChannelEditExpand/>}
                                          rowClick="edit">
                                    <TextField source="translations[0].text" label="Channel Name"/>
                                    <NumberField source="onlineCount"/>
                                    <BooleanField source="isActive"/>
                                </Datagrid>
                            )}{filterValues.allowedCountry === 'in' && (
                            <Datagrid {...props} ids={this.state.in}
                                      rowStyle={rowStyle}
                                      expand={<ChannelEditExpand/>}
                                      rowClick="edit">
                                <TextField source="translations[0].text" label="Channel Name"/>
                                <NumberField source="onlineCount"/>
                                <BooleanField source="isActive"/>
                            </Datagrid>
                        )}{filterValues.allowedCountry === 'all' && (
                            <Datagrid {...props} ids={this.state.all}
                                      rowStyle={rowStyle}
                                      expand={<ChannelEditExpand/>}
                                      rowClick="edit">
                                <TextField source="translations[0].text" label="Channel Name"/>
                                <NumberField source="onlineCount"/>
                                <BooleanField source="isActive"/>
                            </Datagrid>
                        )}
                        </div>
                    }
                    medium={
                        <div>
                            {filterValues.allowedCountry === 'tr' && (
                                <Datagrid {...props} ids={this.state.tr}
                                          rowStyle={rowStyle}
                                          expand={<ChannelEditExpand/>}
                                          rowClick="edit">
                                    <MyImageField source="icon" title="Picture"/>
                                    <ArrayField source="translations">
                                        <SingleFieldList>
                                            <ChipField source="text" styles="chip"/>
                                        </SingleFieldList>
                                    </ArrayField>
                                    <NumberField source="onlineCount"/>
                                    <DateField source="updatedAt" locales="en-GB" showTime/>
                                    <BooleanField source="isActive"/>
                                    <EditButton/>
                                </Datagrid>
                            )}
                            {filterValues.allowedCountry === 'us' && (
                                <Datagrid {...props} ids={this.state.us}
                                          rowStyle={rowStyle}
                                          expand={<ChannelEditExpand/>}
                                          rowClick="edit">
                                    <MyImageField source="icon" title="Picture"/>
                                    <ArrayField source="translations">
                                        <SingleFieldList>
                                            <ChipField source="text" styles="chip"/>
                                        </SingleFieldList>
                                    </ArrayField>
                                    <NumberField source="onlineCount"/>
                                    <DateField source="updatedAt" locales="en-GB" showTime/>
                                    <BooleanField source="isActive"/>
                                    <EditButton/>
                                </Datagrid>
                            )}
                            {filterValues.allowedCountry === 'in' && (
                                <Datagrid {...props} ids={this.state.in}
                                          rowStyle={rowStyle}
                                          expand={<ChannelEditExpand/>}
                                          rowClick="edit">
                                    <MyImageField source="icon" title="Picture"/>
                                    <ArrayField source="translations">
                                        <SingleFieldList>
                                            <ChipField source="text" styles="chip"/>
                                        </SingleFieldList>
                                    </ArrayField>
                                    <NumberField source="onlineCount"/>
                                    <DateField source="updatedAt" locales="en-GB" showTime/>
                                    <BooleanField source="isActive"/>
                                    <EditButton/>
                                </Datagrid>
                            )}{filterValues.allowedCountry === 'all' && (
                            <Datagrid {...props} ids={this.state.all}
                                      rowStyle={rowStyle}
                                      expand={<ChannelEditExpand/>}
                                      rowClick="edit">
                                <MyImageField source="icon" title="Picture"/>
                                <ArrayField source="translations">
                                    <SingleFieldList>
                                        <ChipField source="text" styles="chip"/>
                                    </SingleFieldList>
                                </ArrayField>
                                <NumberField source="onlineCount"/>
                                <DateField source="updatedAt" locales="en-GB" showTime/>
                                <BooleanField source="isActive"/>
                                <EditButton/>
                            </Datagrid>
                        )}
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

export default TabbedDatagrid;