import React, {Component} from 'react';
import {Responsive} from 'react-admin';
import Platform from './Instant'
import LevelStats from './LevelStats'
import UserStats from './NewUsers'
import UserStatsHi from './NewUsersHi'
import UserStatsUs from './NewUsersUs'
import PaymentStats from './NewPayments'
import service from './Service'
import AvatarStats from "./AvatarStats";
import PaymentList from "./PaymentList";

const styles = {
    flex: {display: 'flex'},
    flexSmall: {display: 'block'},
    flexSmallTop: {display: 'block', marginTop: '1em', marginBottom: '1em'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    flexRow: {display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justify: 'flex-start'},
    leftCol: {flex: 5, marginRight: '1em'},
    leftColSmall: {flex: 5},
    rightCol: {flex: 2, marginLeft: '1em'},
    middleCol: {flex: 8, marginRight: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iosOnlineCount: 0,
            andOnlineCount: 0,
            iosOnlineCountHi: 0,
            andOnlineCountHi: 0,
            iosOnlineCountUs: 0,
            andOnlineCountUs: 0,
            premiumAndroidUser: 0,
            premiumIosUser: 0,
            abazaUser: 0,
            womanUser: 0,
            biographyCount:0,
            womanAwardCount:0,
            allPremiumUser: 0,
            level: [],
            AvatarStats: [],
            newUserList:[{
                "id": 1,
                "six": 0,
                "twelve":0,
                "twentyfour":0,
                "sixHi":0,
                "twelveHi":0,
                "twentyfourHi":0,
                "sixUs":0,
                "twelveUs":0,
                "twentyfourUs":0
            }],
            paymentTypeList: [],
            newPaymentList: [{
                "id": 1,
                "twentyfourAnd": 0,
                "twentyfourIos": 0,
                "sevenDayAnd": 0,
                "sevenDayIos": 0,
            }]
        };
    }

    componentDidMount() {
        service.dataFetcher('all').then(response => this.setState({
            iosOnlineCount: response.iosOnlineCount,
            andOnlineCount: response.andOnlineCount,
            iosOnlineCountHi: response.iosOnlineCountHi,
            andOnlineCountHi: response.andOnlineCountHi,
            iosOnlineCountUs: response.iosOnlineCountUs,
            andOnlineCountUs: response.andOnlineCountUs,
            premiumAndroidUser: response.premiumAndroidUser,
            premiumIosUser: response.premiumIosUser,
            abazaUser: response.abazaUser,
            womanUser: response.womanUser,
            allPremiumUser: response.allPremiumUser,
            level: response.level,
            newUserList: response.newUserList,
            newPaymentList: response.newPaymentList,
            AvatarStats: response.AvatarStats,
            paymentTypeList: response.paymentTypeList,
            biographyCount:response.biographyCount,
            womanAwardCount:response.womanAwardCount
        }));

    }

    /* componentDidUpdate(prevState) {
         if (this.state.andOnlineCount !== prevState.andOnlineCount) {
             service.dataFetcher('all').then(response => this.setState({
                 iosOnlineCount: response.iosOnlineCount,
                 andOnlineCount: response.andOnlineCount,
                 premiumAndroidUser: response.premiumAndroidUser,
                 premiumIosUser: response.premiumIosUser,
                 abazaUser: response.abazaUser,
                 allPremiumUser: response.allPremiumUser,
             }));
         }

     }*/

    render() {
        return (
            <Responsive
                small={
                    <div style={styles.flexSmallTop}>
                        <div style={styles.leftColSmall}>
                            <div style={styles.flexSmall}>
                                <Platform type="IOS Online User"
                                          value={this.state.iosOnlineCount}/>
                                <Platform type="AND Online User"
                                          value={this.state.andOnlineCount}/>
                            </div>
                            <div style={styles.flexSmall}>
                                <Platform type="IOS Premium User"
                                          value={this.state.premiumIosUser}/>
                                <Platform type="AND Premium User"
                                          value={this.state.premiumAndroidUser}/>
                            </div>
                            <div style={styles.flexSmall}>
                                <Platform type="Abaza User"
                                          value={this.state.abazaUser}/>
                                <Platform type="All Premium User"
                                          value={this.state.allPremiumUser}/>
                            </div>
                        </div>
                    </div>
                }
                medium={
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.flex}>
                                <Platform type="IOS Online User"
                                          value={this.state.iosOnlineCount}/>
                                <Platform type="AND Online User"
                                          value={this.state.andOnlineCount}/>
                            </div>
                            <div style={styles.flex}>
                                <Platform type="IOS Online India "
                                          value={this.state.iosOnlineCountHi}/>
                                <Platform type="AND Online India"
                                          value={this.state.andOnlineCountHi}/>
                            </div>
                            <div style={styles.flex}>
                                <Platform type="IOS Online USA"
                                          value={this.state.iosOnlineCountUs}/>
                                <Platform type="AND Online USA"
                                          value={this.state.andOnlineCountUs}/>
                            </div>
                            <div style={styles.flex}>
                                <Platform type="IOS Premium User"
                                          value={this.state.premiumIosUser}/>
                                <Platform type="AND Premium User"
                                          value={this.state.premiumAndroidUser}/>
                            </div>
                            <div style={styles.flex}>
                                <Platform type="Abaza User"
                                          value={this.state.abazaUser}/>
                                <Platform type="All Premium User"
                                          value={this.state.allPremiumUser}/>
                            </div>
                            <div style={styles.flex}>
                                <Platform type="Woman User"
                                          value={this.state.womanUser}/>
                                <Platform type="Award Count"
                                          value={this.state.womanAwardCount}/>

                            </div>
                            <div style={styles.flex}>
                                <Platform type="Biography Count"
                                          value={this.state.biographyCount}/>

                            </div>
                            <div style={styles.flex}>
                                <UserStats visitors={this.state.newUserList}/>
                            </div>
                            <div style={styles.flex}>
                                <UserStatsHi visitors={this.state.newUserList}/>
                            </div>
                            <div style={styles.flex}>
                                <UserStatsUs visitors={this.state.newUserList}/>
                            </div>

                            <div style={styles.flex}>

                            </div>

                            <div style={styles.singleCol}>
                            </div>
                        </div>
                        <div style={styles.middleCol}>
                            <div style={styles.flexRow}>
                                <AvatarStats visitors={this.state.AvatarStats}/>
                            </div>
                            <div style={styles.flexRow}>
                                <PaymentStats visitors={this.state.newPaymentList}/>
                            </div>
                        </div>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <LevelStats visitors={this.state.level}/>
                            </div>
                            <div style={styles.flex}>
                                <PaymentList visitors={this.state.paymentTypeList}/>
                            </div>
                        </div>
                    </div>
                }
            />
        )
    }

}

