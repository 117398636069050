import React from "react";
import PropTypes from 'prop-types';

import {BooleanInput, Edit,SimpleForm,ArrayInput,SimpleFormIterator,DisabledInput} from 'react-admin';

const ChannelTitle = ({record}) => {
    return <span>Channel {record ? `" ${record.translations[0].text}"` : ''}</span>;
};

const ChannelEdit = ({permissions, ...props}) => (
    <Edit title={<ChannelTitle />} undoable={false} label="Edit"  {...props}>
        <SimpleForm>
            <BooleanInput source="isActive"/>
            <DisabledInput source="color" picker="Swatches"/>
            <ArrayInput source="translations">
                <SimpleFormIterator>
                    <DisabledInput source="language"/>
                    <DisabledInput source="text"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

ChannelEdit.propTypes = {
    id: PropTypes.any,
    location: PropTypes.object,
    match: PropTypes.object,
    permissions: PropTypes.string,
};

export default ChannelEdit;
