import React from "react";
import { makeWoman } from './fetch';


class MakeWomen extends React.Component {

    onClick(userId) {
        const token = localStorage.getItem('token');
        makeWoman(token, userId).then(done => {
            if (done.success) {
                alert("Women selected")
            }

        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        const userId = this.props.record.id
        return (
            <div>
                <button onClick={() => {
                    this.onClick(userId)
                }} type="button">Make Woman - (isWoman:{JSON.stringify(this.props.record.isWoman)})</button>
            </div>
        )
    }
}

export default MakeWomen;
