import React from 'react';
import Typography from '@material-ui/core/Typography';

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">App Users</Typography>
        <Typography variant="body1">
            Eiusmod adipisicing tempor duis qui. Ullamco aliqua tempor incididunt aliquip aliquip qui ad minim aliqua. Aute et magna quis pariatur irure sunt. Aliquip velit consequat dolore ullamco laborum voluptate cupidatat. Proident minim reprehenderit id dolore elit sit occaecat ad amet tempor esse occaecat enim. Laborum aliqua excepteur qui ipsum in dolor et cillum est.
        </Typography>
    </div>
);

export default Aside;