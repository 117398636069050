import React from "react";
import PropTypes from 'prop-types';
import {
    DisabledInput,
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    NumberInput,
} from 'react-admin';
const UserTitle = ({ record }) => {
    return <span>User {record ? `" ${record.nickname}"` : ''}</span>;
};
const UserEditExpand = ({permissions, ...props}) => (
    <Edit title={<UserTitle/>}  {...props}>
        <TabbedForm>
            <FormTab label="Edit" path="">
                <DisabledInput source="id"/>
                <TextInput source="nickname"/>
                <NumberInput source="score"/>
                <NumberInput source="limits.availableFriendRequest"/>
                <NumberInput source="limits.availableDoubleCall"/>
                <BooleanInput source="addPremium"/>
                <NumberInput source="premiumDay"/>
            </FormTab>
        </TabbedForm>
    </Edit>

);
UserEditExpand.propTypes = {
    id: PropTypes.any,
    location: PropTypes.object,
    match: PropTypes.object,
    permissions: PropTypes.string,
};
export default UserEditExpand;
