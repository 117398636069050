import React, {Fragment} from 'react';
import ResetScoreButton from './ResetScoreButton';
import UserEditExpand from './UserEditExpand';
import {
    Datagrid,
    Pagination,
    EditButton,
    Filter,
    List,
    Responsive,
    SelectInput,
    SimpleList,
    TextField,
    NumberInput,
    TextInput,
    BooleanField,
    NumberField, DateField
} from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <ResetScoreButton label="Reset Views" {...props} />
    </Fragment>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100,0]} {...props} />
const UserFilter = ({permissions, ...props}) => (
    <Filter {...props} >
        <TextInput label="Email Address" source="username" alwaysOn/>
        <TextInput label="nickname" source="nickname" />
        <NumberInput label="Number" source="number" alwaysOn/>
        <SelectInput label="User Type" source="filterQueries" alwaysOn choices={[
            {id: 'isPremium', name: 'Premium'},
            {id: 'isAbaza', name: 'Abaza'},
            {id: 'isWoman', name: 'Woman'},
            {id: 'isAdmin', name: 'Admin'},
        ]}/>
    </Filter>
);
const UserList = ({permissions, ...props}) => (
    <List {...props}
          filters={<UserFilter permissions={permissions}/>}
          pagination={<PostPagination/>}
          bulkActionButtons={<PostBulkActionButtons/>}
    ><Responsive
        small={
            <SimpleList
                primaryText={user => user.username}
                secondaryText={user => 'Score :' + user.score}
            />
        }
        medium={
            <Datagrid expand={<UserEditExpand/>} rowClick="show">
                <TextField source="nickname" label="Nickname" />
                <TextField source="username"/>
                <BooleanField source="isOnline"/>
                <BooleanField source="isPremium"/>
                <NumberField source="score"/>
                <NumberField source="rank"/>
                <BooleanField source="isAbaza"/>
                <TextField label="Level" source="level.name"/>
                <DateField source="updatedAt" locales="en-GB" showTime/>
                <EditButton/>
            </Datagrid>
        }
    />
    </List>
);
export default UserList;