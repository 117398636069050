import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { translate } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 10,
    },
    card: {
        padding: 5,
        textAlign: 'left',
        flexDirection: 'row',
    },
    title: {
        padding: '0 12px',
    },
    value: {
        padding: '0 15px',
        minHeight: 32,
    },
    
    listItemText: {
        paddingRight: 0,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    ListItem:{
        width:'auto',
        float:'left',
        minWidth:'150px'}
});

const AvatarStats = ({ visitors = [], classes }) => (
    <div className={classes.main}>
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
            </Typography>
            <Typography
                variant="h5"
                component="h2"
                className={classes.value}>
                New Avatar Statics
            </Typography>
            <Divider />
            <List>
                {visitors.map(record => (
                    <ListItem
                        key={record._id.toString()}
                        className={classes.ListItem}>
                        <Avatar
                            src={`${record.url}?size=32x32`}
                            className={classes.avatar}
                        />
                        <ListItemText
                            primary={`${record.count}`}
                            className={classes.listItemText}
                        />
                    </ListItem>
                ))}
            </List>
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(AvatarStats);